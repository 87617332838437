var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_box" }, [
        !_vm.isShowPage ? _c("div", [_vm._m(0)]) : _vm._e()
      ]),
      _vm.isShowPage
        ? _c(
            "div",
            { staticClass: "personbox" },
            [
              _c("van-nav-bar", {
                attrs: {
                  title: "我的",
                  "safe-area-inset-top": "",
                  fixed: "",
                  placeholder: "",
                  border: false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "right",
                      fn: function() {
                        return [
                          _c("img", {
                            attrs: { src: _vm.settingsIcon, alt: "" },
                            on: { click: _vm.loginout }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  827151436
                )
              }),
              _c("div", { staticClass: "rel" }, [
                _c("div", { staticClass: "phonttop" }),
                _c("div", { staticClass: "flex abs topbox" }, [
                  _c(
                    "div",
                    { staticClass: "imgbox" },
                    [
                      _vm.uerinfo.avatar
                        ? [
                            _c("img", {
                              attrs: { src: _vm.uerinfo.avatar, alt: "" }
                            })
                          ]
                        : [
                            _vm.uerinfo.realName
                              ? [
                                  _c("img", {
                                    attrs: { src: _vm.processEnv, alt: "" }
                                  })
                                ]
                              : [
                                  _vm.uerinfo.auth == "UNPOST"
                                    ? _c("img", {
                                        attrs: { src: _vm.processEnv, alt: "" }
                                      })
                                    : _vm._e(),
                                  _vm.uerinfo.auth == "POST"
                                    ? _c("img", {
                                        attrs: { src: _vm.processEnv, alt: "" }
                                      })
                                    : _vm._e()
                                ]
                          ]
                    ],
                    2
                  ),
                  _c("div", { staticClass: "namebox" }, [
                    _c("div", [
                      _c("div", { staticClass: "postBox" }, [
                        _vm.uerinfo.realName
                          ? _c("p", [_vm._v(_vm._s(_vm.uerinfo.realName))])
                          : _vm._e(),
                        _vm.uerinfo.auth == "UNPOST" ||
                        _vm.uerinfo.auth == "FAIL"
                          ? _c(
                              "div",
                              {
                                attrs: { "data-info": "certification" },
                                on: { click: _vm.isAutoGraph }
                              },
                              [_vm._m(1)]
                            )
                          : _vm.uerinfo.auth == "POST"
                          ? _c("div", [_vm._m(2)])
                          : _vm.uerinfo.auth == "DONE"
                          ? _c("div", [
                              !_vm.uerinfo.hasOwnProperty("sign")
                                ? _c(
                                    "div",
                                    { staticClass: "pleaseCertify" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "AccountContract",
                                              query: { pricing: true }
                                            }
                                          }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/btn_post_examine@2x.png"),
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : !_vm.uerinfo.hasOwnProperty("faceVerify")
                                ? _c(
                                    "div",
                                    { staticClass: "pleaseCertify" },
                                    [
                                      _c(
                                        "router-link",
                                        { attrs: { to: { name: "face" } } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/btn_post_examine@2x.png"),
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _c("div", { staticClass: "pleaseCertify" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/btn_post_examine@2x.png"),
                                        alt: ""
                                      }
                                    })
                                  ])
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("p", { staticClass: "phone" }, [
                      _vm._v(_vm._s(_vm.uerinfo.phone))
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "topcenter" }, [
                _c("div", { staticClass: "box" }, [
                  _c("h1", [_vm._v("常用功能")]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(0)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", { attrs: { src: _vm.sellIcon, alt: "" } })
                        ]),
                        _c("span", [_vm._v("卖料订单")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(1)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", { attrs: { src: _vm.buyIcon, alt: "" } })
                        ]),
                        _c("span", [_vm._v("买料订单")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(5)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.changeMaterialIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("换料订单")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(2)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", { attrs: { src: _vm.stockIcon, alt: "" } })
                        ]),
                        _c("span", [_vm._v("存料订单")])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(4)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.materialIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("提料管理")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(6)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.incomingIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("来料管理")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(3)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.depositIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("押金")])
                      ]
                    ),
                    _c("div", { staticClass: "iconBox" })
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("h1", [_vm._v("其他功能")]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(8)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", { attrs: { src: _vm.remindIcon, alt: "" } })
                        ]),
                        _c("span", [_vm._v("价格提醒")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(0)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.expressIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("快递查询")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(1)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.insuredIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("货运保单")])
                      ]
                    ),
                    _c("div", { staticClass: "iconBox" })
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("h1", [_vm._v("其他功能")]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(6)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.businessIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("业务说明")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(2)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.conditionIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("回收标准")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(3)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.bankCardIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("银行账号")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(4)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", { attrs: { src: _vm.wenhaoIcon, alt: "" } })
                        ]),
                        _c("span", [_vm._v("常见问题")])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(9)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", {
                            attrs: { src: _vm.inviteFriendsIcon, alt: "" }
                          })
                        ]),
                        _c("span", [_vm._v("邀请好友")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(7)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", { attrs: { src: _vm.aboutIcon, alt: "" } })
                        ]),
                        _c("span", [_vm._v("关于我们")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.link(5)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "imgminbox" }, [
                          _c("img", { attrs: { src: _vm.onlineIcon, alt: "" } })
                        ]),
                        _c("span", [_vm._v("联系客服")])
                      ]
                    ),
                    _c("div", { staticClass: "iconBox" })
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-dialog",
        {
          model: {
            value: _vm.cershow,
            callback: function($$v) {
              _vm.cershow = $$v
            },
            expression: "cershow"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v("您的账号还未认证，暂无此项权限")
          ]),
          _c(
            "div",
            { staticClass: "gocer flex-around" },
            [
              _c(
                "div",
                {
                  staticClass: "canclebox",
                  on: {
                    click: function($event) {
                      _vm.cershow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c("router-link", { attrs: { to: { name: "certification" } } }, [
                _vm._v("去认证")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "van-dialog",
        {
          model: {
            value: _vm.ceringModel,
            callback: function($$v) {
              _vm.ceringModel = $$v
            },
            expression: "ceringModel"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v("您的认证信息正在审核中，请稍后查看")
          ]),
          _c(
            "div",
            {
              staticClass: "gocer",
              on: {
                click: function($event) {
                  _vm.ceringModel = false
                }
              }
            },
            [_vm._v("确定")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading" }, [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/btn_authentication@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/btn_examine@2x.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }